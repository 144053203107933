.Reference h2 {
    font-size: 2rem;
    padding: 0 10px;
}

.Reference img {
    width: 80%;
}

.ReferenceContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
