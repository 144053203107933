.Dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DropdownLink {
    color: white;
    text-decoration: none;
    width: 100%;
    display: block;
    font-size: 1.25rem;
    padding: 10px;
    border: 1px solid white;
}

.DropdownLinkText {
    padding: 10px;
}
