.About {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.About img {
    max-width: 90%;
    max-height: 30vh;
    padding: 10px;
    border-radius: 20px;
}

.About p {
    padding: 5px;
    margin: 0;
}
