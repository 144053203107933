.SocialLink {
    margin: 10px;
    padding: 5px;
    border-radius: 50px;
    background-color: #cfced0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 0.3s;
}

.SocialLink .SocialLinkSite {
    opacity: 0.5;
    transition: 0.3s;
    font-size: 1rem;
    margin: 10px;
    vertical-align: baseline;
    color: black;
    width: 100%;
    text-align: right;
}

.SocialLink:hover {
    background: #e87dd0;
}

.SocialLink:hover .SocialLinkSite {
    display: inline;
    opacity: 1;
    color: black;
}

.SocialLinkIcon {
    background: white;
    color: black;
    border-radius: 100px;
    padding: 0;
    text-align: center;
    margin: 5px;
}

.SocialLinkText {
    color: black;
    font-size: 1rem;
    margin: 0;
    padding: 0 10px;
}

.SocialLink svg {
    display: block;
    padding: 10px;
    font-size: 1.75rem;
}
