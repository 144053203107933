.CaptionedImage {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 10px;
}

.CaptionedImage img {
    max-height: 30vh;
    border-radius: 10px;
}

.CaptionedImage p {
    color: black;
    font-size: 0.8rem;
    text-align: right;
    padding: 5px;
    margin: 0;
}

.CaptionedImage a {
    color: blue;
    text-decoration: underline;
}
