.Header {
    width: 100%;
    background: rgba(10, 16, 26, 0.5);
}

.Header a {
    text-decoration: none;
    color: white;
}

.HeaderComponents {
    margin: auto;
    width: 75%;
    display: flex;
    align-items: center;
}

.Header .Logo {
    margin: 0;
    flex-grow: 1;
}

.Logo h1 {
    font-size: 2rem;
    margin: 0;
    padding: 20px 0;
    font-family: 'Comfortaa', sans-serif;
}

.HeaderNavLink {
    font-size: 1.5rem;
    padding: 20px;
}

.HamburgerMenu {
    display: none;
}

@media screen and (max-width: 480px) {
    .Logo h1 {
        padding: 10px;
        font-size: 1.5rem;
    }

    .HeaderComponents {
        width: 90%;
    }

    .HeaderNavLink {
        display: none;
    }

    .HamburgerMenu {
        display: block;
        font-size: 1.5em;
    }
}
