.Home {
    color: white;
}

.Home a {
    color: white;
    text-decoration: none;
}

.HomeContent {
    padding: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.HomeContent div {
    margin: 10px 0;
}

.Title {
    padding: 20px;
    margin: 0;
    color: white;
}

.Badge {
    height: 30vh;
    border-radius: 10px;
}

.HomeLinks {
    background-color: rgba(193, 141, 33, 0.4);
    border-radius: 10px;
    padding: 20px;
    min-width: 30%;
    max-width: 90%;
}

.Greeting {
    display: flex;
    font-size: 18px;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(193, 141, 33, 0.4);
    padding: 20px;
    border-radius: 10px;
}
